.Home{
  flex-grow:1;
  height:100% !important;
  min-height:400px;
  display:flex;
  flex-direction:row;
  align-items: center;
  .myImageContainer{
    min-width:400px;
    max-width:700px;
    flex-grow:0;
    display:block;
    padding:5px 5px 5px 25px;
    .myImage{
      min-width:50%;
      width:100%;
      max-width:100%;
      margin:auto;
      border-radius:50%;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      box-shadow:0px 0px 0px grey;
      transition: 1s;
    }
      .myImage:hover{
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        filter: grayscale(0%);
        box-shadow:0px 0px 10px 6px grey;
      }
  }
  .textContainer{
    flex-grow:1;
    height:400px;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    .textBlock{
      display:block;
    }
    .greeting{
      font-family:'Fredericka the Great';
      font-size:60px;
      font-weight:400;
      animation: hoverText1 9s cubic-bezier(.36,.07,.19,.97) infinite;
      .name{color:#006622}
    }
    .greeting:hover{
      .name{color:#006622}
    }
    @keyframes hoverText1 {
      .name{color:#006622}
    0% {
      transform:translate(0px,-5px);
    }
    1% {
      transform:translate(0px,-10px);
    }
    3%{
      transform:translate(0px,2px);
    }
    5% {
      transform:translate(0px,-1px);
    }
    6% {
     transform:translate(0px,0px);
    }
  }
    .fullStackDev{
      font-family:'Dancing Script';
      font-weight: 100;
      font-size:40px;
      margin: -30px auto -30px auto; 
      animation: hoverText2 9s cubic-bezier(.36,.07,.19,.97) infinite;
    }
    .fullStackDev:hover{
    }
    @keyframes hoverText2 {
    20% {
      transform: rotateZ(0deg);
    }
    21%{
      transform: rotateZ(8deg);
    }
    22% {
      transform: rotateZ(-5deg);
    }
    23% {
     transform: rotateZ(2deg);
    }
    24%{
      transform: rotateZ(0deg);
    }
  }
    .graduate{
      font-family: 'Felipa';
      font-size:30px;
      animation: hoverText4 9s cubic-bezier(.36,.07,.19,.97) infinite;
    }
    .graduate:hover{
    }
    @keyframes hoverText4 {
    40% {
      transform: rotateX(0deg);
    }
    41%{
      transform: rotatex(180deg);
    }
    42% {
      transform: rotateX(0deg);
    }
    43% {
     transform: rotateX(180deg);
    }
    44%{
      transform: rotateX(0deg);
    }
  }
    .loveIt{
      font-family: 'Amatic SC';
      font-size:36px;
      font-weight:bold;
      animation: hoverText5 9s cubic-bezier(.36,.07,.19,.97) infinite;
      margin: -20px auto 0 auto;
    }
    .loveIt:hover{
    }
    @keyframes hoverText5 {
    69% {
      transform:translate(0px,0px);
    }
    70% {
      transform:translate(-10px,0px);
    }
    71%{
      transform:translate(-10px,-10px);
    }
    72%{
      transform:translate(10px,-10px);
    }
    73% {
      transform:translate(10px,10px);
    }
    74% {
      transform:translate(-10px,10px);
    }
    75% {
     transform:translate(-10px,0px);
    }
    76%{
      transform:translate(0px,0px);
    }
  }
  }
}
@media screen and (max-width:800px){
  .Home{
    flex-grow:1;
    height:100% !important;
    min-height:400px;
    display:flex;
    flex-direction:column;
    .myImageContainer{
      min-width:300px;
      width:95%;
      padding:5px 5px 5px 5px;
      .myImage{
        width:auto;
        margin:auto;
        border-radius:50%;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
    .textContainer{
      width:100%;
      height:auto;
      justify-content: flex-start;
      .textBlock{
        margin:5px 0px 5px 0px;
      }
      .greeting{
        font-family:'Fredericka the Great';
        font-size:40px;
        font-weight:400;
        animation: hoverText1 9s cubic-bezier(.36,.07,.19,.97) infinite;
        .name{color:#006622}
      }
      .fullStackDev{
        font-family:'Major Mono Display';
        font-size:20px;
        font-weight:bold;
        animation: hoverText2 9s cubic-bezier(.36,.07,.19,.97) infinite;
      }
      .whoIAm{
        font-family: 'Oranienbaum';
        font-size:16px;
        font-weight:bold;
        animation: hoverText3 9s cubic-bezier(.36,.07,.19,.97) infinite;
      }
      .graduate{
        font-family: 'Felipa';
        font-size:16px;
        font-weight:bold;
        animation: hoverText4 9s cubic-bezier(.36,.07,.19,.97) infinite;
      }
      .loveIt{
        font-family: 'Amatic SC';
        font-size:20px;
        font-weight:bold;
        animation: hoverText5 9s cubic-bezier(.36,.07,.19,.97) infinite;
      }
    }
  }
}
