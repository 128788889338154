.Paintings-container {
 height: 90vh;
  .painting-wrapper{
    height: 86vh;
    display:flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
      .painting-img {
        display: flex;
        max-height: 80vh;
        max-width: 100%;
        margin:auto;
        object-fit: contain !important;
        justify-content: flex-end;
        align-self: flex-end;
        justify-self: flex-end;
        align-items: flex-end;
      }
  }
  .left-button, .right-button{
    cursor:pointer;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    min-width: 3vw;
    padding: 0 10px;
    height: 5vh;
    font-size: 100%;
    svg {
      size: 100px;
      fill: #fff;
    }
  }
  .left-button:hover, .right-button:hover{
    background-color: rgba(255, 255, 255, 0.4);
    transition: all 1s;
  }
  .left-button{
    position: absolute;
    top: 46vh;
    left: 2vw;
  }
  .right-button{
    position:absolute;
    top: 46vh;
    right: 2vw;
  }
}