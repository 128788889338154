.ContactMe{
  flex-grow:1;
  display:flex;
  flex-direction:column;
  justify-content: center;
  .message{
    min-height:300px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    .textLine{
      padding:10px 0px 10px 0px;
    }
    .textLine1{
      font-size:40px;
      font-weight:bold;
      font-family:'Major Mono Display';
    }
    .textLine2{
      font-size:34px;
      font-family:'Noto Serif SC';
    }
    .textLine3{
      font-size:34px;
      font-weight:bold;
      font-family: 'Amatic SC';
      .terarium{
        width:20px;
        display:inline-block;
        animation: terarium 10s  infinite;
    }
      @keyframes terarium {
        5% {
         transform:translate(0px,0px);
        }
        10% {
         transform: translate(300px,0px);
        }
        15% {
         transform: translate(300px,0px);
        }
        20% {
         transform: translate(0px,0px);
        }
      }
      .bug{
        height:20px;
        width:20px;
        animation: bug 10s  infinite;
      }
      @keyframes bug {
        4% {
         transform:rotateZ(0deg);
        }
        5% {
         transform:rotateZ(90deg);
        }
        9% {
         transform: rotateZ(90deg);
        }
        10% {
         transform: rotateZ(270deg);
        }
        14% {
         transform: rotateZ(300deg);
        }
        15% {
         transform: rotateZ(270deg);
        }
        19% {
         transform: rotateZ(270deg);
        }
        20% {
         transform: rotateZ(360deg);
        }
        23% {
         transform: rotateZ(360deg);
        }
        24% {
         transform: rotateZ(360deg);
        }
        25% {
         transform: rotateZ(0deg);
        }
        26%{
          transform: rotateZ(0deg);
        }
      }
    }
    .textLine4{
      font-size:28px;
      font-weight:bold;
      font-family: 'Oranienbaum';
    }
  }
  .mediaContainer{
    .ContactIcon{
      width:60px;
      height:60px;
      border-radius:50%;
      margin:5px;
    }
    .ContactIcon:hover{
      animation: hoverIcon 1s cubic-bezier(.36,.07,.19,.97) both;
      box-shadow:0px 0px 5px black;
    }
    @keyframes hoverIcon{
      0%{
        transform: rotate(0deg);
      }
      90%{
        transform: rotate(726deg);
      }
      100%{
        transform: rotate(720deg);
      }
    }
  }
}
@media screen and (max-width:500px){
  .ContactMe{
      justify-content: flex-start;
    .message{
      .textLine1{
      font-size:30px;
      }
      .textLine2{
      font-size:30px;
      }
      .textLine3{
      font-size:25px;
      }
      .textLine4{
      font-size:16px;
      }
    }
    .mediaContainer{
      .ContactIcon{
        width:40px;
        height:40px;
        border-radius:50%;
        margin:5px;
      }
    }
  }
}
