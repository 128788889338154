.projectCard{
box-shadow:0px 0px 1px black;
width:300px;
height:300px;
margin:10px;
position:relative;
.projectImage{
  width:100%;
}
  .textAndLinksContainer{
    height:100%;
    width:100%;
    position: absolute;
    top:0;
    display:flex;
    flex-direction:column;
    align-content: center;
    background:rgba(0, 0, 0, 0.8);
    color:white;
    padding:10px;
    opacity:0;
    transition-duration: 0.5s;
    .description{
      flex-grow:1;
      font-size:25px;
      width:100%;
      font-weight:bold;
      font-family: 'Amatic SC';
      display:flex;
      flex-direction:column;
      justify-content: space-around;
      .text{
        width:100%;
      }
      .specs{
        font-size:15px;
        color:white;
        text-shadow:0px 0px 10px black;
        font-family:'Gafata';
      }
    }
    .linksContainer{
      height:40px;
      display:flex;
      flex-direction:row;
      justify-content: space-around;
      width:100%;
      a{
        color:white;
      }
      .codeIcon{
        font-size:30px;
      }
      .seeLive{
        font-size:30px;
        color:white;
        .Icon{
          border-radius:50%;
          transition-duration: 0.2s;
          animation: seeLive 4s cubic-bezier(.36,.07,.19,.97) infinite;
        }
        .Icon:hover{
          box-shadow:0px 0px 20px white;
          color:#99ff99;
        }
        @keyframes seeLive {
        0% {
          color:#ffffff;
        }
        50%{
          color:#009933;
        }
        90% {
          color:#009933;
        }
        100% {
          color:#ffffff;
        }
      }
      }
    }
  }
  .tapToSeeDetails{
    width:50px;
    height:50px;
    position: absolute;
    bottom:20px;
    right:10px;
    opacity:1;
    animation: thumbFade 3.6s cubic-bezier(.36,.07,.19,.97) infinite;
    .fingerprint{
      width:40px;
    }
    .tapToSeeDetailsText{
      color:black;
      font-family:'Fredericka the Great';
      font-size:6px;
    }
  }
  @keyframes thumbFade {
    0% {
      opacity:0;
    }
    25%{
      opacity:1;
    }
    80% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
}

.projectCard:hover{
  .textAndLinksContainer{
  opacity:1;
  }
  .tapToSeeDetails{
    display:none;
  }
}
.displayNone{
  display:none;
}
