.Certifications{
  min-height:100%;
  flex-grow:1;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .aboutfcc{
    padding:0px 10px 0px 10px;
    font-size:18px;
    color:black;
    font-family:'Noto Serif SC';
    .fccName{
      color:green;
    }
  }
  .certificate{
    width:300px;
    height:200px;
    margin:10px;
  }
}
.enlargedCertificate{
  max-width:100%;
  max-height:100%;
}
.ant-modal-wrap{
.ant-modal{
  width:75% !important;
  margin:0px auto 0px auto !important;
  text-align:center;
  .verifyCertificate{
    color:green;
    cursor:pointer;
    font-size:20px;
  }
  .verifyCertificate:hover{
    text-decoration:underline;
  }
    .ant-modal-body {
  width:100%;
  padding:0px;
    }
  }
}
@media screen and(max-width:800px){
  .ant-modal-wrap{
    .ant-modal{
      width:100% !important;
      min-width:300px;
      margin:0px auto 0px auto !important;
    .ant-modal-body {
      width:100%;
      padding:0px;
      }
    }
  }
}
