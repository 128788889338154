
.Projects-container{
  .Projects{
    min-height:600px;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
  }
}
