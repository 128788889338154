.App {
  text-align: center;
  min-height:100vh;
  min-width:300px;
  flex:1 0 auto;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  .tabPane{
    display:flex !important;
    flex-direction:column;
    height:100% !important;
    flex: 1 0 auto;
    min-height:600px;
  }

  .ant-tabs-nav{
    .ant-tabs-tab{
      color:black;
      width:90px;
    }
    .ant-tabs-tab-active{
      color:black;
      width:90px;
    }
    .ant-tabs-tab:hover{
      color:black;
      text-shadow:0px 0px 10px #c6c6c6;
    }
    .ant-tabs-ink-bar{
      background:gray;
    }
  }

  footer{
    margin:4px;
    .footerImage{
      width:20px;
      height:20px;
    }
  }
  .icon{
    height:40px;
    transform: rotateZ(0deg);
    transition: 1s;
    width: 50px;
  }
  .icon:hover{
    animation: hover 0.8s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }
  .icon:active{
    animation: hover 0.8s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }
  .aboutMeIcon{
    border-radius:25%;
    width: auto !important;
  }
  .letter-icon{
    margin-bottom: -5px;
  }

  .brush-icon{
  }
  @keyframes hover {
    0%{
      transform: rotate(0deg);
    }
    15%{
      transform: rotate(30deg);
    }
    30%{
      transform: rotate(-30deg);
    }
    100%{
      transform: rotate(0deg);
    }
}

  @media screen and (max-width:800px){
    .icon{
      height:25px;
      margin:0px 0px 0px 0px;
    }
    .ant-tabs-nav .ant-tabs-tab{
      color:black;
      width:auto;
      padding:10px 0px 10px 0px !important;
      text-align:center;
    }
    .ant-tabs-nav .ant-tabs-tab-active{
      color:black;
      width:auto !important;
      border-width:0px 0px 2px 0px;
      border-style:solid;
      border-color:grey;
    }
  }
  .home_button{
    cursor:pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    border: none;
    outline: none;
    font-size: 1.8em;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    padding: 6px 20px 20px 20px;
  }
}
.DarkApp {
  background-color: #000;
  .icon{
    height:40px;
    transform: rotateZ(0deg);
    transition: 1s;
    width: 50px;
    fill: #fff !important;
    color: white;
    filter: invert(100%) sepia(0%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
  }
  .aboutMeIcon{
    border-radius:25%;
    width: auto !important;
    filter: none;
  }
  .letter-icon{
    margin-bottom: -5px;
  }
  .ant-tabs-bar{
    border: none;
    .ant-tabs-nav{
      .ant-tabs-tab{
        color:white;
        width:90px;
      }
      .ant-tabs-tab-active{
        color:white;
        width:90px;
      }
      .ant-tabs-tab:hover{
        color:white;
        text-shadow:0px 0px 10px #c6c6c6;
      }
      .ant-tabs-ink-bar{
        background:white;
        border: 1px solid white;
      }
    }
  }
  @media screen and (max-width:800px){

    .ant-tabs-bar{
      border: none;
      .ant-tabs-nav{
        .ant-tabs-tab{
          color:black;
          width:auto;
          padding:10px 0px 10px 0px !important;
          text-align:center;
          .icon{
            height:25px;
            margin:0px 0px 0px 0px;
          }
        }
        .ant-tabs-tab-active{
          color:black;
          width:auto !important;
          border-width:0px 0px 2px 0px;
          border-style:solid;
          border-color:grey;
        }
      }
    }
  }

  footer{
    color: #fff;
    margin:4px;
    .footerImage{
      width:20px;
      height:20px;
    }
  }
  .Home {
    .myImageContainer {
      .myImage:hover{
        filter: grayscale(0%);
        box-shadow:0px 0px 10px 6px white;
      }
    }
    .textContainer{
      .textBlock{
        color: #fff;
      }
    }
  }
  .Paintings-container {
    .Paintings-header{
      color: #fff;
    }
  }
  .sidetab{
    .ant-tabs-nav .ant-tabs-tab{
      color:#fff;
      width: 100px;
      text-align: left;
      
    }
    .Projects{
      min-height:600px;
      display:flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
    
    }
  }

  .Certifications{
    .aboutfcc{
      color: #fff;
    }
  }
  .ContactMe{
    color: #fff;
    .bug{
      filter: invert(100%) sepia(100%) saturate(2476%) hue-rotate(60deg) brightness(118%) contrast(119%);      
    }
    .codePen-icon{
      filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(80deg) brightness(100%) contrast(100%);
    }
    .github-icon{
      filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(80deg) brightness(200%) contrast(100%);
    }
  }
}
.frederika{font-family:'Fredericka the Great';}
.mmDisplay{font-family:'Major Mono Display';}
.oraniembaum{font-family: 'Oranienbaum';}
.felipa{font-family: 'Felipa';}
.amatic{font-family: 'Amatic SC';}
.noto{font-family:'Noto Serif SC';}


.greet-prompt_wrapper {
  height: 100vh;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: row;
  overflow: hidden;
    .greet-button {
      cursor: pointer;
      border: none;
      width: 50%;
      height: 100%;
      outline: none;
      background-color: rgba(0,0,0,0.1);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button-contents{
        height: 120px;
        width: 120px;

        .icon {
          width: 100%;
          // filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(80deg) brightness(200%) contrast(100%);
        }
        .brush-icon{
          height: 100px; 
        }
        .code-icon{
          height: 100px; 
                    filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(80deg) brightness(200%) contrast(100%);
        }
      }
    }

    .sway{
      animation: sway 3s 1;
    }
    @keyframes sway {
      /* v1
      0% {
       transform:rotate(-10deg) scale(1, 1);;
      }
      30% {
       transform: rotate(390deg) scale(2, 2);
      }
      50% {
       transform: rotate(-90deg) scale(3, 3);
      }
      80% {
       transform: rotate(100deg) scale(4, 4);
      }
      100% {
        transform: rotate(-30deg) scale(5, 5);
      }
      */

      /* V2*/
      0% {
        transform:rotate(-10deg) scale(1, 1);;
       }
      20% {
        transform:rotate(50deg) scale(1.6, 1.6);;
       }
       26% {
        transform:rotate(20deg) scale(1.9, 1.9);;
       }
      50% {
        transform:rotate(-340deg) scale(5, 5);;
       }
       90% {
        transform: rotate(-3000deg) scale(9, 9);
       }
       100% {
         transform: rotate(-90000deg) scale(100, 100);
       }
    }
    .shake{
      animation: shake 3s 1;
    }
    @keyframes shake {
      0% {
       transform:rotate(-10deg) scale(1, 1);;
      }
      15%{
        transform: rotate(10deg) scale(1.5, 1.5);
      }
      30% {
       transform: rotate(-20deg) scale(2, 2);
      }
      50% {
       transform: rotate(20deg) scale(3, 3);
      }
      70% {
       transform: rotate(-10deg) scale(4, 4);
      }
      75% {
        transform: rotate(10deg) scale(5, 5);
       }
       80% {
        transform: rotate(-10deg) scale(5, 5);
       }
       85% {
        transform: rotate(10deg) scale(5, 5);
       }
       90% {
        transform: rotate(-10deg) scale(5, 5);
       }
       95% {
        transform: rotate(10deg) scale(5, 5);
       }
      100% {
        transform: rotate(-10deg) scale(5, 5);
      }
    }
    .grow{
      animation: grow 3s 1;

    }
    @keyframes grow {
      0% {
       width: 50%;
      }
      100% {
        width: 100%;
      }
    }

    .hide{
      opacity: 0;
      width: 0;
      text-align: end;
    }

    .fade-out{
      animation: fade-out 3s 1;
    }
    @keyframes fade-out {
      0% {
       opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .divider{
      border-width: 0 1px 0 0;
      border-color: rgba(118, 118, 118, 0.4);
      border-style: solid;
      height: 100%;
    }
}


.align-rigth{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}